<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center" align="center" class="my-10">
        <v-col
          md="6"
          class="pa-5 text-center py-10"
          style="background-color: #e7f0fa; border-radius: 12px"
        >
          <p style="font-size: 180%; font-weight: 600" class="mb-0">
            Trip Planner
          </p>
          <!-- | With PaLM 2 -->
          <p>
            Trip Planner will recommad you to most suitable plans for your
            trip<br />
            <span style="font-size: 70%">Developed by Vrijraj Singh</span>
          </p>

          <div v-if="userInfo != null">
            <v-avatar>
              <v-img :src="userInfo?.photoURL"></v-img>
            </v-avatar>
            <p class="mb-0" style="font-size: 120%">
              {{ userInfo?.displayName }}
            </p>
            <!--- <p class="mb-0" style="font-size: 90%">{{ userInfo?.email }}</p> -->
          </div>
          <!-- {{  }} -->
          <v-progress-circular
            :size="30"
            width="3"
            color="primary"
            v-if="checkingLoader"
            indeterminate
          ></v-progress-circular>

          <v-btn
            v-if="userInfo == null"
            color="white"
            depressed
            @click="login()"
          >
            <v-icon left>mdi-google</v-icon>
            Login with Google</v-btn
          >

          <v-container fluid class="my-10">
            <v-row class="mb-0">
              <v-col md="3" cols="12" class="my-n2">
                <v-text-field
                  label="Base Location"
                  placeholder="Base Location"
                  outlined
                  v-model="base_place"
                ></v-text-field>
              </v-col>
              <v-col md="3" cols="12" class="my-n2">
                <v-text-field
                  label="Place to Visit"
                  placeholder="Place to Visit"
                  outlined
                  v-model="place"
                ></v-text-field>
              </v-col>
              <v-col md="3" cols="12" class="my-n2">
                <v-text-field
                  label="Number of Days"
                  placeholder="Number of Days"
                  outlined
                  v-model="days"
                ></v-text-field>
              </v-col>
              <v-col md="3" cols="12" class="my-n2">
                <v-text-field
                  label="Budget (INR)"
                  placeholder="Budget (INR)"
                  outlined
                  v-model="budget"
                ></v-text-field>
              </v-col>
              <v-col md="3" cols="12" class="my-n2">
                <v-select
                  :items="foodSelection"
                  outlined
                  label="Food Selection (Veg/No Veg)"
                  v-model="foodpref"
                ></v-select>
              </v-col>
              <v-col md="12" cols="12" class="my-n6">
                <v-btn
                  @click="getRes"
                  depressed
                  :loading="loader"
                  color="primary"
                >
                  <v-icon>mdi-search</v-icon>
                  Show me Results</v-btn
                >
              </v-col>
            </v-row>

            <v-row class="my-10 text-left" v-if="res != null">
              <v-col
                md="12"
                class="pa-5"
                cols="12"
                style="background-color: white; border-radius: 12px"
              >
                <p v-html="res"></p>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import showdown from "showdown";
export default {
  name: "HomeView",
  data: () => ({
    res: null,
    days: null,
    place: null,
    budget: null,
    loader: false,
    base_place: null,
    foodpref: null,
    foodSelection: ["Veg", "Non Veg"],
    checkingLoader: false,
  }),
  components: {},
  computed: {
    userInfo: {
      get() {
        return this.$store.getters["authStore/getUserInfo"];
      },
    },
  },
  mounted() {
    this.checkUser();
  },
  methods: {
    async checkUser() {
      this.checkingLoader = true;
      try {
        let res = await this.$store.dispatch("authStore/CheckState");
        if (res.success) {
          this.checkingLoader = false;
        }
      } catch (error) {
        this.checkingLoader = false;
      }
    },
    login() {
      this.$store.dispatch("authStore/LoginWithGoogle");
    },
    async getRes() {
      this.loader = true;
      try {
        if (
          this.place.length &&
          this.budget > 0 &&
          this.days > 0 &&
          this.base_place.length
        ) {
          let res = this.$store.dispatch("tripStore/getDataFromBackend", {
            days: this.days,
            place: this.place,
            budget: this.budget,
            base_place: this.base_place,
            foodpref: this.foodpref,
          });
          res = await res;
          if (res.data.success) {
            let converter = new showdown.Converter();
            this.res = converter.makeHtml(res.data.data);
          }
          this.loader = false;
        } else {
          alert("Data to Add Karo");
          this.loader = false;
        }
      } catch (error) {
        console.log("err home: ", error);
        this.loader = false;
      }
    },
  },
};
</script>
