/**
 * ES Module for Firebase Auth Service
 * Author: Vrijraj Singh - https://vrijraj.xyz
 * Firebase SDK Version: 9
*/
import {
    signOut,
    signInWithPopup,
    GoogleAuthProvider,
    onAuthStateChanged
} from "firebase/auth";
// import { db } from "@/config/firebase";
// import { doc, getDoc } from "firebase/firestore";

import { auth } from "@/config/firebase";
import router from "@/router";

const state = {
    currentUser: null,
    currentRole: null,
    btnType: null,
    token: null
}

const getters = {
    getUserInfo: (state) => state.currentUser,
    // getRole: (state) => state.currentRole
}

const mutations = {
    setUserInfo: (state, payload) => state.currentUser = payload,
    clearUserInfo: (state) => state.currentUser = null,
    setToken: (state, payload)=> state.token = payload,
    setRole: (state, payload) => state.currentRole = payload,
    clearRole: (state) => state.currentRole = null,
    setBtnType: (state, payload) => state.btnType = payload
}

const actions = {
    /**
     * @description Login with Google
     * @param {*} param0 
     * @returns {Object} - Object
    */
    async LoginWithGoogle({ commit }) {
        const provider = new GoogleAuthProvider();
        try {
            let userInfo = await signInWithPopup(auth, provider);
            commit('setUserInfo', userInfo.user)
            commit('setToken', userInfo.user.accessToken)
            return {
                success: true,
                data: userInfo.user,
            };
        } catch (error) {
            return {
                success: false,
                msg: error,
            }
        }
    },

    /**
     * @description Logout User
     * @param {*} param0 
     * @returns {Object} - Object
    */
    async LogoutUser({ commit }) {
        console.log('logout');
        try {
            await signOut(auth);
            commit('clearUserInfo')
            router.push('/login')
            return {
                success: true,
                message: "User Logout Successfully",
            };
        } catch (error) {
            return {
                success: false,
                msg: error,
            }
        }
    },

    /**
     * @description Fetch User
     * @param {*} param0 
     * @returns {Object} - Object
    */
    async FetchUser({ commit }) {
        console.log('Calling Fetch');
        try {
            let user = auth.currentUser
            console.log('User: ', user);
            if (user == null) {
                commit('clearUserInfo')
                await signOut(auth);
            } else {
                console.log('user', user);
                commit('setUserInfo', user)
            }
            return
        } catch (error) {
            return {
                success: false,
                msg: error,
            }
        }
    },


    /**
     * @description Fetch User
     * @param {*} param0 
     * @returns {Object} - Object
    */
    async CheckState({ commit }) {
        console.log('Calling CheckState');
        try {
            onAuthStateChanged(auth, async(user)=>{
                if(user){
                    commit('setUserInfo', user)
                }else{
                    commit('clearUserInfo')
                    await signOut(auth);
                }
            })
            return {
                success: true
            }
        } catch (error) {
            return {
                success: false,
                msg: error,
            }
        }
    },



}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};