import axios from "axios";
const apiURL = process.env.VUE_APP_SERVICE_URL;

export const apiRequest = (url, type, token, data) => {
  return new Promise((resolve, reject) => {
    try {
      const res = axios({
        method: type,
        url: apiURL + url,
        headers: { Authorization: `Bearer ${token}` },
        data: data,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};