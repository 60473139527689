// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBWOUm6oUaQNDSvgLDpDyHfrc3VRvAlSK8",
  authDomain: "vrij-trip-planner.firebaseapp.com",
  projectId: "vrij-trip-planner",
  storageBucket: "vrij-trip-planner.appspot.com",
  messagingSenderId: "268656788866",
  appId: "1:268656788866:web:e170d7096fad32c4e57b9b",
  measurementId: "G-ZCCX1MMPB4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app)

export { analytics, auth }