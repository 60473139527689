import { apiRequest } from "@/util/httpRequest";

const state = {

}

const getters = {

}

const mutations = {

}

const actions = {
    async getDataFromBackend({ rootState }, data) {
        console.log('data', data);
        console.log('root', rootState.authStore.token);
        return new Promise(async (resolve, reject) => {
            await apiRequest('/v1/trip', "post", rootState.authStore.token, data)
                .then(res => {
                    console.log('ress', res);
                    resolve(res)
                }).catch(err => {
                    console.log('err;', err);
                    reject(err)
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};